import { ALIAS, SECTION, SECTION_IF, SECTION_UNLESS } from 'config/types';
import { READERS } from 'parse/_parse';
import readClosing from './section/readClosing';
import readElse from './section/readElse';
import readElseIf from './section/readElseIf';
import handlebarsBlockCodes from './handlebarsBlockCodes';
import readExpression from '../readExpression';
import refineExpression from 'parse/utils/refineExpression';
import { readAlias, readAliases } from './readAliases';
import { keys } from 'utils/object';

const indexRefPattern = /^\s*:\s*([a-zA-Z_$][a-zA-Z_$0-9]*)/;
const keyIndexRefPattern = /^\s*,\s*([a-zA-Z_$][a-zA-Z_$0-9]*)/;
const handlebarsBlockPattern = new RegExp('^(' + keys(handlebarsBlockCodes).join('|') + ')\\b');

export default function readSection(parser, tag) {
  let expression, section, child, children, hasElse, block, unlessBlock, closed, i, expectedClose;
  let aliasOnly = false;

  const start = parser.pos;

  if (parser.matchString('^')) {
    // watch out for parent context refs - {{^^/^^/foo}}
    if (parser.matchString('^/')) {
      parser.pos = start;
      return null;
    }
    section = { t: SECTION, f: [], n: SECTION_UNLESS };
  } else if (parser.matchString('#')) {
    section = { t: SECTION, f: [] };

    if (parser.matchString('partial')) {
      parser.pos = start - parser.standardDelimiters[0].length;
      parser.error(
        'Partial definitions can only be at the top level of the template, or immediately inside components'
      );
    }

    if ((block = parser.matchPattern(handlebarsBlockPattern))) {
      expectedClose = block;
      section.n = handlebarsBlockCodes[block];
    }
  } else {
    return null;
  }

  parser.sp();

  if (block === 'with') {
    const aliases = readAliases(parser);
    if (aliases) {
      aliasOnly = true;
      section.z = aliases;
      section.t = ALIAS;
    }
  } else if (block === 'each') {
    const alias = readAlias(parser);
    if (alias) {
      section.z = [{ n: alias.n, x: { r: '.' } }];
      expression = alias.x;
    }
  }

  if (!aliasOnly) {
    if (!expression) expression = readExpression(parser);

    if (!expression) {
      parser.error('Expected expression');
    }

    // optional index and key references
    if ((i = parser.matchPattern(indexRefPattern))) {
      let extra;

      if ((extra = parser.matchPattern(keyIndexRefPattern))) {
        section.i = i + ',' + extra;
      } else {
        section.i = i;
      }
    }

    if (!block && expression.n) {
      expectedClose = expression.n;
    }
  }

  parser.sp();

  if (!parser.matchString(tag.close)) {
    parser.error(`Expected closing delimiter '${tag.close}'`);
  }

  parser.sectionDepth += 1;
  children = section.f;

  let pos;
  do {
    pos = parser.pos;
    if ((child = readClosing(parser, tag))) {
      if (expectedClose && child.r !== expectedClose) {
        if (!block) {
          if (child.r)
            parser.warn(
              `Expected ${tag.open}/${expectedClose}${tag.close} but found ${tag.open}/${child.r}${
                tag.close
              }`
            );
        } else {
          parser.pos = pos;
          parser.error(`Expected ${tag.open}/${expectedClose}${tag.close}`);
        }
      }

      parser.sectionDepth -= 1;
      closed = true;
    } else if (!aliasOnly && (child = readElseIf(parser, tag))) {
      if (section.n === SECTION_UNLESS) {
        parser.error('{{else}} not allowed in {{#unless}}');
      }

      if (hasElse) {
        parser.error('illegal {{elseif...}} after {{else}}');
      }

      if (!unlessBlock) {
        unlessBlock = [];
      }

      const mustache = {
        t: SECTION,
        n: SECTION_IF,
        f: (children = [])
      };
      refineExpression(child.x, mustache);

      unlessBlock.push(mustache);
    } else if (!aliasOnly && (child = readElse(parser, tag))) {
      if (section.n === SECTION_UNLESS) {
        parser.error('{{else}} not allowed in {{#unless}}');
      }

      if (hasElse) {
        parser.error('there can only be one {{else}} block, at the end of a section');
      }

      hasElse = true;

      // use an unless block if there's no elseif
      if (!unlessBlock) {
        unlessBlock = [];
      }

      unlessBlock.push({
        t: SECTION,
        n: SECTION_UNLESS,
        f: (children = [])
      });
    } else {
      child = parser.read(READERS);

      if (!child) {
        break;
      }

      children.push(child);
    }
  } while (!closed);

  if (unlessBlock) {
    section.l = unlessBlock;
  }

  if (!aliasOnly) {
    refineExpression(expression, section);
  }

  // TODO if a section is empty it should be discarded. Don't do
  // that here though - we need to clean everything up first, as
  // it may contain removeable whitespace. As a temporary measure,
  // to pass the existing tests, remove empty `f` arrays
  if (!section.f.length) {
    delete section.f;
  }

  return section;
}
